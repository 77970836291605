import React from 'react'
import "./style.scss"
import BottomImg from "../../assets/images/bottom-static-img.svg"
import useFetch from '../../hooks/useFetch';
// import loader from "../../assets/images/rainbow-loader.gif"
const Footer = () => {

    // let { loading, data, error } = useFetch('http://52.5.5.222:1337/api/footer?populate=deep');
    // let { loading, data, error } = useFetch('http://localhost:1337/api/footer?populate=deep');
    let { loading, data, error } = useFetch(`${process.env.REACT_APP_BASE_URL}/api/footer?populate=deep`);
    // console.log("🚀 ~ file: App.js:6 ~ App ~ footer:", data)
    // if (loading) return <div className='loader'><img src={loader} className="img img-fluid" alt="loader" /></div>

    return (
        <>
            <div className='footer'>
                {/* <div className="bottom-img">
                    <img src={BottomImg} className="img img-fluid" alt="BottomImg" />
                </div> */}
                <div className="footer-body">
                    <div className="footer-logo">
                        <img src={data?.attributes?.footerLogo?.data?.attributes?.url} className="img img-fluid" alt="footer-logo" />
                    </div>
                    <div className="footer-text">
                        <div>
                            <span>{data?.attributes?.copyright}&nbsp; | &nbsp;All Rights Reserved</span>&nbsp; | &nbsp;
                            <span>{data?.attributes?.privacyPolicy}</span>&nbsp; | &nbsp;
                            <span>{data?.attributes?.terms}</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer
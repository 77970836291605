import React, { useState } from 'react'
import loader from "../../assets/images/rainbow-loader.gif"
import './style.scss';
const Loader = (WrappedComponent) => {
    function HOC(props) {
        const [isLoading, setLoading] = useState(false);

        const setLoadingState = isComponentLoading => {
            setLoading(isComponentLoading);
        }

        return (
            <div className='loderHOC'>
                {isLoading &&
                    <div className='loader_wrapper'>
                        <img className='loader_img' src={loader} />
                    </div>
                }
                <WrappedComponent {...props} setLoading={setLoadingState} />
            </div>
        )
    }
    return HOC;
}
export default Loader

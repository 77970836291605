import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import useFetch from '../../hooks/useFetch';
import loader from "../../assets/images/rainbow-loader.gif"
import CaseStudiesBanner from "../../assets/images/logo-banner-img.svg"
import DownArrow from "../../assets/images/icons/down-arrow-colored.png"
import "./style.scss"
import DetailFetch from '../../hooks/detailFetch';

const CaseStudies = () => {
    // const [cardMap, setCardMap] = useState([])
    let { loading, data, error } = useFetch(`${process.env.REACT_APP_BASE_URL}/api/casestudy?populate=deep`);
    let { detailData, load } = DetailFetch(`${process.env.REACT_APP_BASE_URL}/api/detail-pages?populate=deep`);

    // console.log("🚀 ~ file: index.js:15 ~ CaseStudies ~ detailData:", detailData)
    // useEffect(() => {
    //     if (detailData) {
    //         delete data.attributes.createdAt
    //         delete data.attributes.updatedAt
    //         delete data.attributes.publishedAt
    //         const detaildata = Object.values(detailData)
    //         console.log("🚀 ~ file: index.js:16 ~ useEffect ~ detaildata:", detaildata)
    //         setCardMap(detaildata)
    //     }
    // }, [detailData])
    console.log("🚀 ~ file: index.js:12 ~ CaseStudies ~ detailData:", detailData)
    // console.log("🚀 ~ file: index.js:10 ~ CaseStudies ~ data:", data)
    if (loading) return <div className='loader loader_wrapper text-center'><img src={loader} className="img img-fluid loader_img" alt="loader" /></div>
    if (load) return <div className='loader loader_wrapper text-center'><img src={loader} className="img img-fluid loader_img" alt="loader" /></div>
    if (error) {
        Swal.fire({
            icon: 'error',
            text: 'No Data Found',
        });
    }
    return (
        <>
            <div className="case-studies">
                {/* CaseStudies-Page-Banner */}
                <div className="casestudies-banner-section">
                    <img src={data?.attributes?.caseStudyBanner?.bannerimg?.data[0]?.attributes?.url} className="img img-fluid banner-img" alt="banner" />
                    <div className="centered">
                        <h1>{data?.attributes?.caseStudyBanner?.title}</h1>
                    </div>
                </div>

                <div className="bg-page-color">
                    <div className="container">
                        {/* Case Studies-Description */}
                        <div className="casestudy-description">
                            <p className='description-top'>
                                {data?.attributes?.descriptionText?.descriptionTop}
                            </p>
                            {/* <p className='description-bottom'>We are the creators of your dream projects who accelerate at a quicker pace. We are those who give accurate output on technology licensing, information, and purpose-driven systems of world-class category talent. We have a bunch of technical implementers of your merchandising strategies, to not simply survive, but thrive. We'd like to place learning and development at the forefront. We have a tendency to grow ourselves and facilitate the growth of others.</p> */}
                            <p className='description-bottom'>{data?.attributes?.descriptionText?.descriptionBottom}</p>
                        </div>

                        <div className="extendime-section">

                            {detailData?.length >= 0 && detailData?.map((item, index) => {
                                console.log("====>DetailCase", item, index % 2 == 0)
                                return (
                                    <>
                                        <div className="row caseStudies-section">
                                            <div className={`col-md-6 move-down ${index % 2 == 0 ? 'order-1 ' : 'order-2  offset-md-1'}`}>
                                                <div className="extendime-img">
                                                    <img src={item?.attributes?.listImg?.data?.attributes?.url} className="img img-fluid banner-img" alt="bannerImg" />
                                                </div>
                                            </div>
                                            <div className={`col-md-5 align-self-center  move-up ${index % 2 == 0 ? 'order-2  offset-md-1' : 'order-1 '}`}>
                                                <h3 className='mb-3'>{item?.attributes?.title}</h3>
                                                <p>{item?.attributes?.description}</p>
                                                <div className='read-btn'>
                                                    <a href={`/extendime/${item?.id}`} className='read-more'>Read more</a>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                            }
                        </div>

                        <div className="load-more text-center">
                            <span className='load-more-btn btn'>Load more &nbsp;
                                <img src={DownArrow} className="img img-fluid arrow-img" alt="mg" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CaseStudies
import React, { useEffect, useState } from 'react'
import "./style.scss";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import useFetch from '../../../hooks/useFetch';
import loader from '../../../assets/images/rainbow-loader.gif';
import Swal from 'sweetalert2';
import UiUxModal from './uiuxmodal';
// import Modal from 'react-bootstrap/Modal';
const UiUxDesign = () => {
    // const [cardMap, setCardMap] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    let { loading, data, error } = useFetch(`${process.env.REACT_APP_BASE_URL}/api/ui-ux-works?populate=deep`);
    // let { loading, data, error } = useFetch('http://localhost:1337/api/ui-ux-works?populate=deep');
    // let { loading, data, error } = useFetch('http://52.5.5.222:1337/api/ui-ux-works?populate=deep');
    console.log("🚀 ~ file: index.js:14 ~ UiUxCards ~ data:", data)
    // useEffect(() => {
    //     if (data) {
    //         const creativedata = Object.values(data?.attributes?.creative_works)
    //         // console.log("🚀 ~ file: index.js:13 ~ useEffect ~ creativedata:", creativedata)
    //         setCardMap(creativedata)
    //     }
    // }, [data])

    // console.log("🚀 ====>works", data)
    if (loading) return <div className='loader loader_wrapper text-center'><img src={loader} className="img img-fluid loader_img" alt="loader" /></div>
    if (error) {
        Swal.fire({
            icon: 'error',
            text: 'No Data Found',
        });
    }



    return (
        <>
            <div className="creative">
                <div className="row">
                    {data?.length >= 0 && data?.map((item, index) => {
                        console.log("====>cretData", item)
                        return (
                            <>
                                <div className="col-md-4">
                                    <div className='' onClick={() => setModalShow(item?.attributes?.UiUxCards
                                    )}>
                                        {/* <a href={`/creativemodal/${item?.id}`} > */}
                                        <Card >
                                            {/* <Card style={{ width: '18rem' }} onClick={item[index]?.id}> */}
                                            <Card.Img variant="top" src={item?.attributes?.UiUxCards
                                                ?.img?.data?.attributes?.url} />
                                            <Card.Body>
                                                <Card.Title className='text-center card-title'>{item?.attributes?.UiUxCards
                                                    ?.title}</Card.Title>
                                            </Card.Body>
                                        </Card>
                                        {/* </a> */}
                                    </div>
                                </div>
                                <UiUxModal
                                    show={modalShow}
                                    onHide={() => setModalShow(false)}
                                // id={item?.id}
                                // data={item?.attributes?.creative_works}
                                />

                            </>
                        )
                    })
                    }
                </div>

            </div>
        </>
    )
}

export default UiUxDesign
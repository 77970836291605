import React, { useEffect, useState } from 'react'
import useFetch from '../../hooks/useFetch';
import loader from "../../assets/images/rainbow-loader.gif"
import "./style.scss"
import Card from 'react-bootstrap/Card';
import Swal from 'sweetalert2';
const ColorPage = () => {
    const [cardMap, setCardMap] = useState([])

    let { loading, data, error } = useFetch(`${process.env.REACT_APP_BASE_URL}/api/colorpage?populate=deep`);
    // let { loading, data, error } = useFetch('http://52.5.5.222:1337/api/colorpage?populate=deep');
    useEffect(() => {
        if (data) {
            const colordata = Object.values(data?.attributes?.BrandColors)
            // console.log("🚀 ~ file: index.js:13 ~ useEffect ~ colordata:", colordata)
            setCardMap(colordata)
        }
    }, [data])


    // console.log("🚀 ~ file: index.js:8 ~ ColorPage ~ data:", data)
    if (loading) return <div className='loader loader_wrapper text-center'><img src={loader} className="img img-fluid loader_img" alt="loader" /></div>
    if (error) {
        Swal.fire({
            icon: 'error',
            text: 'No Data Found',
        });
    }

    return (
        <>
            <div className="color-page">
                {/* Color-Page-Banner */}
                <div className="color-banner-section">
                    <img src={data?.attributes?.colorBanner?.bannerimg?.data?.attributes?.url} className="img img-fluid banner-img" alt="banner" />
                    <div className="centered">
                        {/* <h1 className='stroke'>{data?.attributes?.colorBanner?.title}</h1>
                        <h1 className='stroke'>{data?.attributes?.colorBanner?.title}</h1> */}
                        <h1>{data?.attributes?.colorBanner?.title}</h1>
                        {/* <h1 className='stroke'>{data?.attributes?.colorBanner?.title}</h1>
                        <h1 className='stroke'>{data?.attributes?.colorBanner?.title}</h1> */}
                    </div>
                </div>
                <div className="bg-page-color">
                    <div className="container">
                        {/* Color-Description */}
                        <div className="color-description">
                            <p className='description-top'>
                                {data?.attributes?.descriptionText?.descriptionTop}
                            </p>
                            <p className='description-bottom'>{data?.attributes?.descriptionText?.descriptionBottom}</p>
                        </div>

                        {/* Color-Cards */}
                        <div className="color-cards">
                            <h2 className='title'>Brand Colors</h2>
                            <div className="cards">
                                <div className="row">
                                    {cardMap?.length >= 0 && cardMap?.map((item, index) => {
                                        // console.log("---------------->", item)
                                        return (
                                            <>
                                                <div className="col-md-4">
                                                    <Card className='brand-color-cards'>
                                                        <Card.Img variant="top" src={item?.img?.data?.attributes?.url} className="img img-fluid card-img" />
                                                        <Card.Body className='card_body'>
                                                            <span className='rgb'>{item?.rgb}</span><br />
                                                            <span className='rgba'>{item?.rgba}</span>
                                                            <Card.Title className='card-title'>{item?.colorTitle}</Card.Title>
                                                            <Card.Text>
                                                                {item?.description}
                                                            </Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </>
                                        )
                                    })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ColorPage
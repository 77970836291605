import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import useFetch from '../../hooks/useFetch';
import loader from "../../assets/images/rainbow-loader.gif"
import Card from 'react-bootstrap/Card';
import "./style.scss"

const BrandManual = () => {
    const [cardMap, setCardMap] = useState([])
    let { loading, data, error } = useFetch(`${process.env.REACT_APP_BASE_URL}/api/brand-manual?populate=deep`);
    // let { loading, data, error } = useFetch('http://localhost:1337/api/brand-manual?populate=deep');
    useEffect(() => {
        if (data) {
            const colordata = Object.values(data?.attributes?.Classifications)
            // console.log("🚀 ~ file: index.js:13 ~ useEffect ~ colordata:", colordata)
            setCardMap(colordata)
        }
    }, [data])

    console.log("🚀 ====>BrandManual", data)
    if (loading) return <div className='loader loader_wrapper text-center'><img src={loader} className="img img-fluid loader_img" alt="loader" /></div>
    if (error) {
        Swal.fire({
            icon: 'error',
            text: 'No Data Found',
        });
    }
    return (
        <>
            <div className='brand-manual'>
                {/* BrandManual-Page-Banner */}
                <div className="brandmanual-banner-section">
                    <img src={data?.attributes?.BrandManualBanner?.bannerimg?.data?.attributes?.url} className="img img-fluid banner-img" alt="banner" />
                    <div className="centered">
                        <h1>{data?.attributes?.BrandManualBanner?.title}</h1>
                    </div>
                </div>

                <div className="bg-page-color">
                    <div className="container">
                        {/* BrandManual-Description */}
                        <div className="casestudy-description">
                            <p className='description-top'>
                                {data?.attributes?.descriptionText?.descriptionTop}
                            </p>
                            {/* <p className='description-bottom'>We are the creators of your dream projects who accelerate at a quicker pace. We are those who give accurate output on technology licensing, information, and purpose-driven systems of world-class category talent. We have a bunch of technical implementers of your merchandising strategies, to not simply survive, but thrive. We'd like to place learning and development at the forefront. We have a tendency to grow ourselves and facilitate the growth of others.</p> */}
                            <p className='description-bottom'>{data?.attributes?.descriptionText?.descriptionBottom}</p>
                        </div>

                        {/* Classifications Section */}
                        <div className="classification-section">
                            <div className="classification-cards">

                                <div className="cards">
                                    <h2 className='title'>Classifications</h2>
                                    <div className="row">
                                        {cardMap?.length >= 0 && cardMap?.map((item, index) => {
                                            // console.log("---------------->", item)
                                            return (
                                                <>
                                                    <div className="col-md-4">
                                                        <Card className='brand-color-cards'>
                                                            <Card.Img variant="top" src={item?.img?.data?.attributes?.url} className="img img-fluid card-img" />
                                                            <Card.Body className='card_body'>
                                                                <Card.Title className='card-title'>{item?.title}</Card.Title>
                                                                <Card.Text>
                                                                    {item?.description}
                                                                </Card.Text>
                                                                <a href={item?.url} className='readmore'>Read more</a>
                                                            </Card.Body>
                                                        </Card>
                                                    </div>
                                                </>
                                            )
                                        })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BrandManual
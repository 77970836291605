import React from 'react'
import useFetch from '../../hooks/useFetch';
import loader from "../../assets/images/rainbow-loader.gif"
import "./style.scss"
import Swal from 'sweetalert2';
const ElementsPage = () => {
    let { loading, data, error } = useFetch(`${process.env.REACT_APP_BASE_URL}/api/elementspage?populate=deep`);

    // let { loading, data, error } = useFetch('http://localhost:1337/api/elementspage?populate=deep');
    // let { loading, data, error } = useFetch('http://52.5.5.222:1337/api/elementspage?populate=deep');
    if (loading) return <div className='loader loader_wrapper text-center'><img src={loader} className="img img-fluid loader_img" alt="loader" /></div>
    if (error) {
        Swal.fire({
            icon: 'error',
            text: 'No Data Found',
        });
    }

    return (
        <>
            <div className="elements-page">
                {/* Typography-Page-Banner */}
                <div className="elements-banner-section">
                    <img src={data?.attributes?.elementsBanner?.bannerimg?.data?.attributes?.url} className="img img-fluid banner-img" alt="banner" />
                    <div className="centered">
                        {/* <h1 className='stroke'>{data?.attributes?.colorBanner?.title}</h1>
                        <h1 className='stroke'>{data?.attributes?.colorBanner?.title}</h1> */}
                        <h1>{data?.attributes?.elementsBanner?.title}</h1>
                        {/* <h1 className='stroke'>{data?.attributes?.colorBanner?.title}</h1>
                        <h1 className='stroke'>{data?.attributes?.colorBanner?.title}</h1> */}
                    </div>
                </div>
                <div className="bg-page-color">
                    <div className="container">
                        {/* Color-Description */}
                        <div className="typo-description">
                            <p className='description-top'>
                                {data?.attributes?.descriptionText?.descriptionTop}
                            </p>
                            <p className='description-bottom'>{data?.attributes?.descriptionText?.descriptionBottom}</p>
                        </div>

                        {/* Images-Section */}
                        <div className="images-section">
                            <div className="row">
                                <div className="col-md-6 align-self-center">
                                    <h2 className='title2'>{data?.attributes?.Images?.title}</h2>
                                    <p className='width'>{data?.attributes?.Images?.description}</p>
                                </div>
                                <div className="col-md-6">
                                    <div className="bg-image">
                                        <img src={data?.attributes?.Images?.bgImg?.data?.attributes?.url} className="img img-fluid" alt="bg-vector-img" />
                                        <img src={data?.attributes?.Images?.imgPosition1?.data?.attributes?.url} className='img img-fluid image-position1' alt="image1" />
                                        <img src={data?.attributes?.Images?.imgPosition2?.data?.attributes?.url} className='img img-fluid image-position2' alt="image2" />
                                        <img src={data?.attributes?.Images?.imgPosition3?.data?.attributes?.url} className='img img-fluid image-position3' alt="image3" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Illustrations-Section */}
                        <div className="illustration-section">
                            <div className="row">
                                <div className="col-md-6 align-self-center order-2 order-sm-1">
                                    <div className="bg-img">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <img src={data?.attributes?.Illustrations?.imgTop?.data?.attributes?.url} className="img img-fluid" alt="bg-vector-img" />
                                            </div>
                                            <div className="col-md-6"></div>
                                            <div className="col-md-6"></div>
                                            <div className="col-md-6">
                                                <img src={data?.attributes?.Illustrations?.imgBottom?.data?.attributes?.url} className="img img-fluid" alt="bg-vector-img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 align-self-center order-1 order-sm-2">
                                    <div className="">
                                        <h2 className='title2'>{data?.attributes?.Illustrations?.title}</h2>
                                        <p>{data?.attributes?.Illustrations?.description}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Icons-Section */}
                        <div className="icons-section">
                            <div className="row">
                                <div className="col-md-6 align-self-center">
                                    <h2 className='title2'>{data?.attributes?.Icons?.title}</h2>
                                    <p className='width'>{data?.attributes?.Icons?.description}</p>
                                </div>
                                <div className="col-md-6 align-self-center">
                                    <div className="icons">
                                        <div className="row">
                                            <div className="col-4 col-sm-4 col-xs-4 my-3">
                                                <img src={data?.attributes?.Icons?.icon1?.data?.attributes?.url} className="img img-fluid" alt="bg-vector-img" />
                                            </div>
                                            <div className="col-4 col-sm-4 col-xs-4 my-3">
                                                <img src={data?.attributes?.Icons?.icon2?.data?.attributes?.url} className="img img-fluid" alt="bg-vector-img" />
                                            </div>
                                            <div className="col-4 col-sm-4 col-xs-4 my-3">
                                                <img src={data?.attributes?.Icons?.icon3?.data?.attributes?.url} className="img img-fluid" alt="bg-vector-img" />
                                            </div>
                                            <div className="col-4 col-sm-4 col-xs-4 my-3">
                                                <img src={data?.attributes?.Icons?.icon4?.data?.attributes?.url} className="img img-fluid" alt="bg-vector-img" />
                                            </div>
                                            <div className="col-4 col-sm-4 col-xs-4 my-3">
                                                <img src={data?.attributes?.Icons?.icon5?.data?.attributes?.url} className="img img-fluid" alt="bg-vector-img" />
                                            </div>
                                            <div className="col-4 col-sm-4 col-xs-4 my-3">
                                                <img src={data?.attributes?.Icons?.icon6?.data?.attributes?.url} className="img img-fluid" alt="bg-vector-img" />
                                            </div>
                                        </div>
                                        {/* <img src={data?.attributes?.Icons?.bgImg?.data?.attributes?.url} className="img img-fluid" alt="bg-vector-img" /> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default ElementsPage
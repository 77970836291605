import React, { useEffect, useState } from 'react'
import useFetch from '../../hooks/useFetch';
import loader from "../../assets/images/rainbow-loader.gif"
import "./style.scss"
import Swal from 'sweetalert2';
const TypographyPage = () => {
    const [typoState, setTypoState] = useState([]);
    let { loading, data, error } = useFetch(`${process.env.REACT_APP_BASE_URL}/api/typographypage?populate=deep`);
    // let { loading, data, error } = useFetch('http://localhost:1337/api/typographypage?populate=deep');
    // let { loading, data, error } = useFetch('http://52.5.5.222:1337/api/typographypage?populate=deep');

    useEffect(() => {
        if (data) {
            const typodata = Object.values(data?.attributes?.fontFamily)
            console.log("🚀 ~ file: index.js:13 ~ useEffect ~ typodata:", typodata)
            setTypoState(typodata)
        }
    }, [data])

    if (loading) return <div className='loader loader_wrapper text-center'><img src={loader} className="img img-fluid loader_img" alt="loader" /></div>
    if (error) {
        Swal.fire({
            icon: 'error',
            text: 'No Data Found',
        });
    }

    return (
        <>
            <div className="typography-page">
                {/* Typography-Page-Banner */}
                <div className="typography-banner-section">
                    <img src={data?.attributes?.typoBanner?.bannerimg?.data?.attributes?.url} className="img img-fluid banner-img" alt="banner" />
                    <div className="centered">
                        <h1>{data?.attributes?.typoBanner?.title}</h1>
                    </div>
                </div>
                <div className="bg-page-color">
                    <div className="container">
                        {/* Color-Description */}
                        <div className="typo-description">
                            <p className='description-top'>
                                {data?.attributes?.descriptionText?.descriptionTop}
                            </p>
                            <p className='description-bottom'>{data?.attributes?.descriptionText?.descriptionBottom}</p>
                        </div>
                        {/* Typography-Fonts */}
                        <div className="typo-font">
                            <div className="row">
                                {typoState?.length >= 0 && typoState?.map((item, index) => {
                                    console.log("====>typo", item)
                                    return (
                                        <>
                                            <div className="col-md-6 my-3">
                                                <h2 className='title2'>{item?.title}</h2>
                                                <div className="typo-family my-3">
                                                    <div className="row m-auto">
                                                        <div className="col-md-6">
                                                            <h2 className='typo-family-large'>{item?.fontLarge}</h2>
                                                        </div>
                                                        <div className="col-md-6 align-self-center">
                                                            {/* <p className='typo-family-black'>Aa</p> */}
                                                            <div className="row">
                                                                <div className="col-6 col-sm-6 col-xs-6 text-center">
                                                                    <p className='typo-family-black'>{item?.fontSmall}</p>
                                                                </div>
                                                                <div className="col-6 col-sm-6 col-xs-6 text-center">
                                                                    <p className='typo-family-gray'>{item?.fontSmall}</p>
                                                                </div>
                                                                <div className="col-6 col-sm-6 col-xs-6 text-center">
                                                                    <p className='typo-family-blue'>{item?.fontSmall}</p>
                                                                </div>
                                                                <div className="col-6 col-sm-6 col-xs-6 text-center">
                                                                    <p className='typo-family-electricblue'>{item?.fontSmall}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="description">
                                                    <p>{item?.description}</p>
                                                </div>
                                            </div>
                                            <div className="col-md-4 offset-2 align-self-center my-3">
                                                <div className="font-family-regular">
                                                    <p className='regular'>{item?.fontRegular}</p>
                                                    <p className='letters'>{item?.fontLetters}</p>
                                                    <p className='numbers'>{item?.fontNumbers}</p>
                                                </div>
                                                <div className="font-family-bold ">
                                                    <p className='regular'>{item?.fontBold}</p>
                                                    <p className='letters'>{item?.fontBoldLetters}</p>
                                                    <p className='numbers'>{item?.fontBoldNumbers}</p>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default TypographyPage
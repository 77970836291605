import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Logo from '../../assets/images/logo-white.svg'
import Facebook from '../../assets/images/icons/facebook.svg'
import Insta from '../../assets/images/icons/insta.svg'
import Twitter from '../../assets/images/icons/twitter.svg'
import Linkedin from '../../assets/images/icons/linkdin.svg'
import "./style.scss"
import useFetch from '../../hooks/useFetch';
import loader from "../../assets/images/rainbow-loader.gif"
function Header() {
    const [colorChange, setColorchange] = useState(false);
    const [subMenu, setSubMenu] = useState([]);
    const changeNavbarColor = () => {
        if (window.scrollY >= 80) {
            setColorchange(true);
        }
        else {
            setColorchange(false);
        }
    };
    window.addEventListener('scroll', changeNavbarColor);

    // let { loading, data, error } = useFetch('http://52.5.5.222:1337/api/menu?populate=deep');
    // let { loading, data, error } = useFetch('http://localhost:1337/api/menu?populate=deep');
    let { loading, data, error } = useFetch(`${process.env.REACT_APP_BASE_URL}/api/menu?populate=deep`);

    useEffect(() => {
        if (data) {
            const menu = Object.values(data?.attributes?.body)
            console.log("🚀 ~ file: index.js:13 ~ useEffect ~ menu:", menu)
            setSubMenu(menu)
        }
    }, [data])
    if (loading) return <div className='loader loader_wrapper text-center'><img src={loader} className="img img-fluid loader_img" alt="loader" /></div>
    return (
        <>

            <div className="header">
                {[false].map((expand) => (
                    <Navbar key={expand} expand={expand} className={colorChange ? 'navbar colorChange' : 'navbar'}>
                        <Container className=''>
                            {/* <Container className='nav-fixed fixed-top' > */}
                            <Navbar.Brand href="/"><img src={data?.attributes?.body[4]?.Logo?.data?.attributes?.url} className="img img-fluid main-logo" alt="main-logo" /></Navbar.Brand>
                            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                            <Navbar.Offcanvas
                                id={`offcanvasNavbar-expand-${expand}`}
                                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                                placement="end"
                            >
                                <div className="container">
                                    <Offcanvas.Header closeButton>
                                        <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                            <img src={Logo} className="img img-fluid" alt="" />
                                        </Offcanvas.Title>
                                    </Offcanvas.Header>
                                    <Offcanvas.Body>
                                        <Nav className="justify-content-end flex-grow-1 pe-3">
                                            {subMenu?.length >= 0 && subMenu?.map((item, index) => {
                                                console.log("====>subMenu", item)

                                                // {
                                                //     item?.submenus?.data[0].attributes.links.map((number) => {
                                                //         console.log("==============tester", number);
                                                //     })
                                                // }

                                                return (
                                                    <>

                                                        {/* <Nav.Link href="#action2" className='main-nav-link'>Work</Nav.Link> */}
                                                        <div className="dropdown">
                                                            <Nav.Link href={item?.url} className="dropbtn main-nav-link">{item?.name}</Nav.Link>
                                                            <div className="dropdown-content">
                                                                {/* <div className='droplist'> */}
                                                                {item?.submenus?.data[0].attributes.links.map((subItem, index) => {
                                                                    console.log("====>subMenuIndex", subItem, index)
                                                                    return (
                                                                        <>
                                                                            <a href={subItem?.url} className='nav-link'>{subItem?.name}</a>
                                                                        </>
                                                                    )
                                                                })
                                                                }
                                                                {/* </div> */}
                                                            </div>
                                                        </div>


                                                    </>
                                                )
                                            })
                                            }
                                            {/* <div className="dropdown">
                                                <button className="dropbtn">Dropdown</button>
                                                <div className="dropdown-content">
                                                    <a href="#">Link 1</a>
                                                    <a href="#">Link 2</a>
                                                    <a href="#">Link 3</a>
                                                </div>
                                            </div> */}

                                            <div className="social-icons">
                                                <Nav.Link href="https://www.facebook.com/login/" className='social-icon-link'><img src={Facebook} className="img img-fluid" alt="linkedin" /></Nav.Link>
                                                <Nav.Link href="https://www.instagram.com/accounts/login/" className='social-icon-link'><img src={Insta} className="img img-fluid" alt="linkedin" /></Nav.Link>
                                                <Nav.Link href="https://twitter.com/i/flow/login" className='social-icon-link'><img src={Twitter} className="img img-fluid" alt="linkedin" /></Nav.Link>
                                                <Nav.Link href="https://www.linkedin.com/login" className='social-icon-link'><img src={Linkedin} className="img img-fluid" alt="linkedin" /></Nav.Link>
                                            </div>
                                        </Nav>
                                    </Offcanvas.Body>
                                </div>
                            </Navbar.Offcanvas>
                        </Container>
                    </Navbar>
                ))}
            </div>

        </>
    );
}

export default Header;
import React, { useEffect, useState } from 'react'
import "./style.scss"
import LogoBannerImg from "../../assets/images/bd1.png"
import Logo from "../../assets/images/Logo.png"
import useFetch from '../../hooks/useFetch';
import loader from "../../assets/images/rainbow-loader.gif"
import WordmarkLogo from "../../assets/images/Horizintal_logo_colored.svg"
import DollarbirdImgLight from "../../assets/images/dollarbirdimg.svg"
// import DollarbirdImgLight from "../../assets/images/dollarbirdimg.svg"
import WordMarkDark from "../../assets/images/word-mark-logo.svg"
import WordmarkLogoLight from "../../assets/images/Horizintal_logo_white.svg"
import BorderedLogo from "../../assets/images/bordered-logo.svg"
import DbLogoWhite from "../../assets/images/db-logo-white.svg"
import LogoWhite from "../../assets/images/logo-white.svg"
import LogoBlack from "../../assets/images/logo-black.svg"
import DbLogoColored from "../../assets/images/db-logo-colored.svg"
import LogoColored from "../../assets/images/dollarbird_color_logo.svg"
import LogoSizeTop from "../../assets/images/logo-size1.svg"
import LogoSizeBottom from "../../assets/images/logo-size2.svg"
import Swal from 'sweetalert2';
const LogoPage = () => {
    // const [logoimg, setLogoimg] = useState([]);
    let { loading, data, error } = useFetch(`${process.env.REACT_APP_BASE_URL}/api/logopage?populate=deep`);
    // let { loading, data, error } = useFetch('http://localhost:1337/api/logopage?populate=deep');
    // let { loading, data, error } = useFetch('http://52.5.5.222:1337/api/logopage?populate=deep');
    // useEffect(() => {
    //     if (data) {
    //         const brandLogo = Object.values(data.attributes)
    //         console.log("🚀 ~ file: index.js:26 ~ useEffect ~ brandLogo:", brandLogo)

    //         setLogoimg(brandLogo)
    //     }
    // }, [])

    console.log("🚀 ~ file: App.js:6 ~ App ~ logopage:", data)
    if (loading) return <div className='loader loader_wrapper text-center'><img src={loader} className="img img-fluid loader_img" alt="loader" /></div>
    if (error) {
        Swal.fire({
            icon: 'error',
            text: 'No Data Found',
        });
    }

    return (
        <>
            <div className="logo-page">

                {/* Logo-Banner */}
                <div className="logo-banner-section">
                    <img src={data?.attributes?.LogoBanner?.bannerimg?.data?.attributes?.url} className="img img-fluid banner-img" alt="banner" />
                    <div className="centered">
                        <h1>{data?.attributes?.LogoBanner?.title}</h1>
                    </div>
                </div>
                <div className="bg-page-color">
                    <div className="container">
                        {/* Logo-Wordmark */}
                        <div className="word-mark">
                            <h2 className='title'>Bird/ Wordmark</h2>
                            <div className="word-mark-top">
                                <div className="row">
                                    <div className="col-md-6 text-center">
                                        <img src={data?.attributes?.WordMark?.wordmarkLogo?.data?.attributes?.url} className='img img-fluid bird-img1' alt="wordmarklogo" />
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            {/* <div className="col-md-4 text-center">
                                                <img src={data?.attributes?.WordMark?.dollarbirdImgLight?.data?.attributes?.url} className="img img-fluid" alt="dollarbirdlight" />
                                            </div> */}
                                            {/* <div className="col-md-4 text-center">
                                                <img src={data?.attributes?.WordMark?.dollarbirdImgLight?.data?.attributes?.url} className="img img-fluid" alt="dollarbirdlight" />
                                            </div>
                                            <div className="col-md-4 text-center">
                                                <img src={data?.attributes?.WordMark?.dollarbirdImgLight?.data?.attributes?.url} className="img img-fluid" alt="dollarbirdlight" />
                                            </div> */}
                                            <div className="col-md-12 text-center">
                                                <img src={data?.attributes?.WordMark?.wordMarkDark?.data?.attributes?.url} className="img img-fluid db-logo-img" alt="dollarbirdlight" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="word-mark-bottom">
                                <div className="row g-0">
                                    <div className="col-md-2">
                                        <div className="bg-logo1 img-bird">
                                            <img src={data?.attributes?.WordMark?.wordmarkLogoLight?.data?.attributes?.url} className="img img-fluid" alt="wordmark" />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="bg-logo2 img-bird">
                                            <img src={data?.attributes?.WordMark?.wordmarkLogoLight?.data?.attributes?.url} className="img img-fluid" alt="wordmark" />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="bg-logo3 img-bird">
                                            <img src={data?.attributes?.WordMark?.wordmarkLogoLight?.data?.attributes?.url} className="img img-fluid" alt="wordmark" />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="bg-logo1 img-bird">
                                            {/* <img src={WordmarkLogoLight} className="img img-fluid" alt="wordmark" /> */}
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="bg-logo2 img-bird">
                                            {/* <img src={WordmarkLogoLight} className="img img-fluid" alt="wordmark" /> */}
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="bg-logo3 img-bird">
                                            {/* <img src={WordmarkLogoLight} className="img img-fluid" alt="wordmark" /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Exclusive-Zone */}

                        <div className="exclusive-zone">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="zone1-text">
                                        <h2 className='title'>{data?.attributes?.exclusiveZone?.title}</h2>
                                        <p className='description'>{data?.attributes?.exclusiveZone?.description}</p>
                                    </div>
                                </div>
                                <div className="col-md-6 align-self-center">
                                    <img src={data?.attributes?.exclusiveZone?.img?.data?.attributes?.url} className='img img-fluid' alt="" />
                                </div>
                            </div>
                        </div>

                        {/* Logo-Size */}

                        <div className="logo-size">
                            <div className="row">
                                <div className="col-md-6 align-self-center">
                                    <div className="logo-size-text">
                                        <h2 className='title'>{data?.attributes?.minLogoSize?.title}</h2>
                                        <p className='description'>{data?.attributes?.minLogoSize?.description}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <img src={data?.attributes?.minLogoSize?.logoSizeMax?.data?.attributes?.url} className="img img-fluid" alt="" />
                                        </div>
                                        <div className="col-md-6 align-self-center text-left">
                                            <span className='size'>{data?.attributes?.minLogoSize?.pixelMaxSize}</span>
                                        </div>
                                        <div className="col-md-6 align-self-center text-end order-2 order-sm-1">
                                            <span className='size'>{data?.attributes?.minLogoSize?.pixelMinSize}</span>
                                        </div>
                                        <div className="col-md-6 order-1 order-sm-2">
                                            <img src={data?.attributes?.minLogoSize?.logoSizeMin?.data?.attributes?.url} className="img img-fluid" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Logos-Brand */}
                        <div className="logos-brand">
                            <div className="row">
                                {/* {logoimg.length >= 0 && logoimg.map((item, index) => {
                                console.log("----------------Display item>", item)
                                return (
                                    <>
                                        <div className="col-md-4">
                                            <div className="bg-dark-logo">
                                                <div>
                                                    <img src={item[index]?.dBLogoWhite?.data?.attributes?.url} className="img img-fluid my-3" alt="" />
                                                </div>
                                                <div>
                                                    <img src={item[index]?.logoWhite?.data?.attributes?.url} className="img img-fluid my-3" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                            } */}
                                <div className="col-md-4 my-3">
                                    <div className="bg-dark-logo">
                                        <div>
                                            <img src={data?.attributes?.logoPlacement?.logoWhiteTop?.data?.attributes?.url} className="img img-fluid my-3" alt="" />
                                        </div>
                                        <div>
                                            <img src={data?.attributes?.logoPlacement?.logoWhiteBottom?.data?.attributes?.url} className="img img-fluid my-3" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 my-3">
                                    <div className="bg-colored-logo">
                                        <div>
                                            <img src={data?.attributes?.logoPlacement?.logocoloredTop?.data?.attributes?.url} className="img img-fluid my-3" alt="" />
                                        </div>
                                        <div>
                                            <img src={data?.attributes?.logoPlacement?.logocoloredBottom?.data?.attributes?.url} className="img img-fluid my-3" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 my-3">
                                    <div className="bg-white-logo">
                                        <div>
                                            <img src={data?.attributes?.logoPlacement?.logoBlackTop?.data?.attributes?.url} className="img img-fluid my-3" alt="" />
                                        </div>
                                        <div>
                                            <img src={data?.attributes?.logoPlacement?.logoBlackBottom?.data?.attributes?.url} className="img img-fluid my-3" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/* Logo Placements */}
                        <div className="logo-placements">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="zone1-text">
                                        <h2 className='title'>{data?.attributes?.logoPlacement?.title}</h2>
                                    </div>
                                </div>
                                <div className="col-md-6 align-self-center">
                                    <p className='description'> {data?.attributes?.logoPlacement?.description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default LogoPage
import React from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Swal from 'sweetalert2';
import loader from '../../assets/images/rainbow-loader.gif';
import useFetch from '../../hooks/useFetch';
import CreativeCards from './creative-cards';
import "./style.scss";
import UiUxDesign from './ui-ux-cards';
const WorksPage = () => {
    let { loading, data, error } = useFetch(`${process.env.REACT_APP_BASE_URL}/api/workspage?populate=deep`);

    // let { loading, data, error } = useFetch('http://localhost:1337/api/workspage?populate=deep');
    // let { loading, data, error } = useFetch('http://52.5.5.222:1337/api/workspage?populate=deep');
    if (loading) return <div className='loader loader_wrapper text-center'><img src={loader} className="img img-fluid loader_img" alt="loader" /></div>
    if (error) {
        Swal.fire({
            icon: 'error',
            text: 'No Data Found',
        });
    }
    return (
        <>
            <div className="works-page">
                <div className="bg-page-color">
                    <div className="container">
                        <h2 className='work-title'>Works</h2>
                        <div className="typo-description">
                            <p className='description-top'>
                                {data?.attributes?.descriptionText?.descriptionTop}
                            </p>
                            <p className='description-bottom'>{data?.attributes?.descriptionText?.descriptionBottom}</p>
                        </div>

                        <div className='work-tabs'>
                            <Tabs
                                defaultActiveKey="home"
                                id="fill-tab-example"
                                className="mb-3"
                                fill
                            >
                                <Tab eventKey="home" title="Creative">
                                    <CreativeCards />
                                </Tab>
                                <Tab eventKey="profile" title="UI/UX Design">
                                    <UiUxDesign />
                                </Tab>
                            </Tabs>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}

export default WorksPage
import React, { useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useParams } from 'react-router-dom';
import useFetch from '../../../../hooks/useFetch';
import "./style.scss";
import { Carousel } from 'react-bootstrap';
const CreativeModal = (props) => {
    console.log("🚀 ~ file: index.js:7 ~ CreativeModal ~ props:", props.show)

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props?.show?.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    {props?.show?.description}
                </p>
                <span className=''><b>Dribble:</b>&nbsp;
                    <a href={props?.show?.url} className='dribble-link'>{props?.show?.url}</a> </span>
                <Carousel>
                    {
                        props?.show?.imgs?.length >= 0 && props?.show?.imgs?.map((item) => {
                            return (
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={item?.img?.data?.attributes?.url}
                                    />
                                </Carousel.Item>
                            )
                        })
                    }

                </Carousel>
            </Modal.Body>
        </Modal>
    )
}

export default CreativeModal
import logo from './logo.svg';
import './App.scss';
import Header from './components/header';
import Footer from './components/footer';
import { Route, Router, Switch } from "react-router-dom";
import HomePage from './pages/homepage';
import LogoPage from './pages/logopage';
import ColorPage from './pages/colorpage';
import TypographyPage from './pages/typographypage';
import ElementsPage from './pages/elementspage';
import ContactPage from './pages/contactpage';
import ScrollToTop from './components/scrolltotop'
import CaseStudies from './pages/casestudies';
import Extendime from './pages/extendimepage';
import BrandManual from './pages/brandmanual';
import useFetch from './hooks/useFetch';
import NoData from './assets/images/empty1.png';
// import detailFetch from './hooks/detailFetch';
import DetailFetch from './hooks/detailFetch';
import WorksPage from './pages/workspage';
import loader from "./assets/images/rainbow-loader.gif"
import NoInternetConnection from './components/nointernetconnection';
// import CreativeModal from './pages/workspage/creative-cards/creativemodal';
// import UiUxDesign from './pages/workspage/ui-ux-cards';
function App() {
  let { loading, data, error } = useFetch(`${process.env.REACT_APP_BASE_URL}/api/homepage?populate=deep`);
  let { detailData } = DetailFetch(`${process.env.REACT_APP_BASE_URL}/api/detail-pages?populate=deep`);
  console.log("🚀 ~ file: App.js:26 ~ App ~ detailData:", detailData);

  return (
    <>

      {/* {data?.attributes?.bannerSection ? */}
      <div className="App scrollbar-width-none">
        <NoInternetConnection>
          <ScrollToTop>
            <Header />
            <Switch>
              <Route exact path="/" >{<HomePage />}</Route >
              <Route exact path="/brandmanual" >{<BrandManual />}</Route >
              <Route exact path="/logo" >{<LogoPage />}</Route >
              <Route exact path="/color" >{<ColorPage />}</Route >
              <Route exact path="/typography" >{<TypographyPage />}</Route >
              <Route exact path="/elements" >{<ElementsPage />}</Route >
              <Route exact path="/contact" >{<ContactPage />}</Route >
              <Route exact path="/casestudies" >{<CaseStudies />}</Route >
              <Route exact path="/extendime/:id" >{<Extendime details={detailData ? detailData : ""} />}</Route >
              <Route exact path="/works" >{<WorksPage />}</Route >
            </Switch>
            <Footer />
          </ScrollToTop>
        </NoInternetConnection>
      </div>
      {/* :
        <div className='nodata'>
          <img src={NoData} className="img img-fluid nodata-img" alt='' />
          <img src={loader} className="img img-fluid loader_img" alt="loader" />
        </div>
      } */}

    </>

  );
}

export default App;

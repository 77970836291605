import { useEffect, useState } from 'react'
// import Loader from "../components/loader"
const useFetch = (url) => {
    const [data, setData] = useState(null)
    const [error, setError] = useState(null)
    const [loading, setloading] = useState(true)

    useEffect(() => {
        const fetchData = async () => {
            setloading(true)
            try {
                const res = await fetch(url)
                const json = await res.json()
                const mainjson = json.data
                // console.log("🚀 ~ file: useFetch.js:14 ~ fetchData ~ json", mainjson)
                setData(mainjson)
                setloading(false)
            } catch (error) {
                setError(error)
                setloading(false)
            }
        }
        fetchData()

    }, [url])

    return { loading, data, error }
}

export default useFetch
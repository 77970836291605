import React from 'react'
// import { Detector } from 'react-detect-offline';
import NoInternet from '../../assets/images/icons/noconnection_icon.png'
import "./style.scss";
import { useContext, useEffect, useRef, useState } from "react";
const NoInternetConnection = (props) => {
    // return (
    //     <>      <Detector
    //         // polling={{ interval: 9000 }}
    //         // polling={{ url: { BASE_URL } }}
    //         render={({ online }) => {
    //             return (
    //                 online ? props.children :
    //                     <div className='nointernet_screen'>
    //                         <img src={Images.nointernet} className="img img-fluid" alt="nointernet" />
    //                         <h1>No Connection</h1>
    //                         <h4>Please Check your Internet Connection</h4>
    //                     </div>
    //             )
    //         }
    //         }
    //     />
    //     </>
    // )
    // state variable holds the state of the internet connection
    const [isOnline, setOnline] = useState(true);

    // On initization set the isOnline state.
    useEffect(() => {
        setOnline(navigator.onLine)
    }, [])

    // event listeners to update the state 
    window.addEventListener('online', () => {
        setOnline(true)
    });

    window.addEventListener('offline', () => {
        setOnline(false)
    });

    // if user is online, return the child component else return a custom component
    if (isOnline) {
        return (
            props.children
        )
    } else {
        return (
            <div className='nointernet_screen'>
                <img src={NoInternet} className="img img-fluid" alt="nointernet" />
                <h1>No Connection</h1>
                <h4>Please Check your Internet Connection</h4>
            </div>
        )
    }
}

export default NoInternetConnection
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import useFetch from '../../hooks/useFetch';
import loader from "../../assets/images/rainbow-loader.gif"
// import LogoBanner from "../../assets/images/logo-grid.svg"
// import ExtendimeLogo from "../../assets/images/extendime_logo.svg"
// import ExtendimeLogoImg from "../../assets/images/extendime-img.svg"
// import WireFrameImgOne from "../../assets/images/Homepage_6-2.svg"
import BgCircle from "../../assets/images/bg-circle.png"
import "./style.scss"
import { useParams } from 'react-router-dom';
// const Extendime = () => {
const Extendime = ({ details }) => {
    // const [dataDetails, setDataDetails] = useState([])
    console.log(">>>====>", details)
    const { id } = useParams();
    const values = Object.values(details);
    let data = {}
    if (data) {
        let arr = values?.filter(data => data.id == id)
        data = arr[0]
    } else {
        data = {}
    }
    // setDataDetails(data);
    // useEffect(() => {
    //     setDataDetails(data)
    // }, [])

    console.log("data======>", data)

    // if (load) return <div className='loader loader_wrapper text-center'><img src={loader} className="img img-fluid loader_img" alt="loader" /></div>

    if (!data) return <div className='loader loader_wrapper text-center'><img src={loader} className="img img-fluid loader_img" alt="loader" /></div>
    // const [process, setProcess] = useState([])
    // let { loading, data, error } = useFetch('http://localhost:1337/api/extendimepage?populate=deep');
    // // let { loading, data, error } = useFetch('http://localhost:1337/api/detail-pages?populate=deep');

    // useEffect(() => {
    //     if (data) {
    //         const processdata = Object.values(data?.attributes?.DetailProcess)
    //         // console.log("🚀 ~ file: index.js:13 ~ useEffect ~ processdata:", processdata)
    //         setProcess(processdata)
    //     }
    // }, [data])


    // console.log("🚀 ~ file: index.js:10 ~ extendimepage ~ data:", data)
    // if (loading) return <div className='loader loader_wrapper text-center'><img src={loader} className="img img-fluid loader_img" alt="loader" /></div>

    // if (error) {
    //     Swal.fire({
    //         icon: 'error',
    //         text: 'No Data Found',
    //     });
    // }
    return (
        <>
            {/* {dataDetails?.length > 0 && dataDetails?.map((item, index) => {
                console.log("detailpage", item, index)
                return (
                    <>
                    </>
                )
            })
            } */}
            <div className="extendime-page">
                <div className="container">
                    <div className="banner my-5">
                        <img src={data?.attributes?.bannerimg?.data?.attributes?.url} className="banner-img img img-fluid" alt="banner-img" />
                    </div>

                    <div className="detail-about">
                        <div className="row">
                            <div className="col-md-5">
                                <h2 className='detail-title'>{data?.attributes?.DetailAbout?.title}</h2>
                            </div>
                            <div className="col-md-6 offset-md-1">
                                <p className='description'>{data?.attributes?.DetailAbout?.description}</p>
                                <div className="detail-about-flex">
                                    <div className='d-flex gap-3'>
                                        <img src={data?.attributes?.DetailAbout?.flag?.data?.attributes?.url} className="img img-fluid" alt="" />
                                        <span className='text'>{data?.attributes?.DetailAbout?.countryName}</span>
                                    </div>
                                    <div className='d-flex gap-3'>
                                        <img src={data?.attributes?.DetailAbout?.logo?.data?.attributes?.url} className="img img-fluid" alt="" />
                                        <span className='text'>{data?.attributes?.DetailAbout?.Technology}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="detail-process section">
                        <div className="row">
                            <div className="col-md-6 my-3 tester">
                                <h3>{data?.attributes?.DetailProcess[0]?.title}</h3>
                                <p>{data?.attributes?.DetailProcess[0]?.description}</p>
                            </div>
                            <div className="col-md-6 my-3 tester">
                                <h3>{data?.attributes?.DetailProcess[1]?.title}</h3>
                                <p>{data?.attributes?.DetailProcess[1]?.description}</p>
                            </div>
                            <div className="col-md-6 my-3 tester">
                                <h3>{data?.attributes?.DetailProcess[2]?.title}</h3>
                                <p>{data?.attributes?.DetailProcess[2]?.description}</p>
                            </div>
                            <div className="col-md-6 my-3 tester">
                                <h3>{data?.attributes?.DetailProcess[3]?.title}</h3>
                                <p>{data?.attributes?.DetailProcess[3]?.description}</p>
                            </div>
                        </div>
                    </div>

                    {/* <div className="detail-process section">
                        <div className="row">
                            {process?.length >= 0 && process?.map((item, index) => {
                                return (
                                    <>
                                        <div className="col-md-6 my-3 tester">
                                            <h3>{item?.title}</h3>
                                            <p>{item?.description}</p>
                                        </div>
                                      
                                    </>
                                )
                            })
                            }
                        </div>
                    </div> */}

                    {/* Logo-Design Section */}
                    <div className={`logo-design section ${data?.attributes?.LogoDesign <= 0 ? 'd-none' : 'd-block'}`}>
                        <h2 className='detail-title'>Logo Design</h2>
                        <div className="circle-bg">
                            <img src={BgCircle} className="img img-fluid" alt="circle_Design_cs1" />
                        </div>

                        <div className="logo-design-imgs">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 my-3">
                                        <div className="logo-img-div">
                                            <img src={data?.attributes?.LogoDesign[0]?.logoImg?.data[0]?.attributes?.url} className="img img-fluid" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 my-3">
                                        <div className="logo-img-div">
                                            <img src={data?.attributes?.LogoDesign[1]?.logoImg?.data[0]?.attributes?.url} className="img img-fluid" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 my-3">
                                        <div className="logo-img-div">
                                            <img src={data?.attributes?.LogoDesign[2]?.logoImg?.data[0]?.attributes?.url} className="img img-fluid" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 my-3">
                                        <div className="logo-img-div">
                                            <img src={data?.attributes?.LogoDesign[3]?.logoImg?.data[0]?.attributes?.url} className="img img-fluid" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 my-3">
                                        <div className="logo-img-div">
                                            <img src={data?.attributes?.LogoDesign[4]?.logoImg?.data[0]?.attributes?.url} className="img img-fluid" alt="" />
                                        </div>
                                    </div>

                                    <div className="col-md-12 my-3">
                                        <div className="logo-img-div">
                                            <img src={data?.attributes?.LogoDesign[5]?.logoImg?.data[0]?.attributes?.url} className="img img-fluid w-100                                                                                                   " alt="" />
                                        </div>
                                    </div>

                                    <div className="col-md-6 my-3">
                                        <div className="logo-img-div">
                                            <img src={data?.attributes?.LogoDesign[6]?.logoImg?.data[0]?.attributes?.url} className="img img-fluid" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 my-3">
                                        <div className="logo-img-div">
                                            <img src={data?.attributes?.LogoDesign[7]?.logoImg?.data[0]?.attributes?.url} className="img img-fluid" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 my-3">
                                        <div className="logo-img-div">
                                            <img src={data?.attributes?.LogoDesign[8]?.logoImg?.data[0]?.attributes?.url} className="img img-fluid" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 my-3">
                                        <div className="logo-img-div">
                                            <img src={data?.attributes?.LogoDesign[9]?.logoImg?.data[0]?.attributes?.url} className="img img-fluid" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* WireFrames Section */}
                    <div className={`wireframes section ${data?.attributes?.WireFrames <= 0 ? 'd-none' : 'd-block'}`}>
                        <h2 className='detail-title'>Wireframes</h2>
                        <div className="circle-bg">
                            <img src={BgCircle} className="img img-fluid" alt="circle_Design_cs1" />
                        </div>
                        <div className="container">
                            <div className="wireframe-imgs">
                                <div className="row">
                                    <div className="col-md-6">
                                        <img src={data?.attributes?.WireFrames?.firstImg?.data?.attributes?.url} className="img img-fluid" alt="" />
                                    </div>
                                    <div className="col-md-6">
                                        <img src={data?.attributes?.WireFrames?.secondImg?.data?.attributes?.url} className="img img-fluid" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Typography Section */}
                    <div className={`typography section ${data?.attributes?.TypographyDesktop <= 0 ? 'd-none' : 'd-block'}`}>
                        {/* <div className={`typography section ${data?.attributes?.typography <= 0 ? 'd-none' : 'd-block'}`}> */}
                        <h2 className='detail-title'>Typography</h2>
                        <div className="circle-bg">
                            <img src={BgCircle} className="img img-fluid" alt="circle_Design_cs1" />
                        </div>
                        <div className="typography-imgs">
                            <div className="row">
                                <div className="col-md-12 align-self-center text-center my-3 d-none d-md-block">
                                    <img src={data?.attributes?.TypographyDesktop?.img?.data?.attributes?.url} className="img img-fluid" alt="" />
                                </div>
                                <div className="col-md-12 align-self-center text-center my-3 d-block d-md-none">
                                    <img src={data?.attributes?.TypographyMobile?.img?.data?.attributes?.url} className="img img-fluid" alt="" />
                                </div>
                                {/* <div className="col-md-6 align-self-center text-center my-3">
                                    <img src={data?.attributes?.typography?.img1?.data?.attributes?.url} className="img img-fluid" alt="" />
                                </div>
                                <div className="col-md-6 align-self-center text-center my-3">
                                    <img src={data?.attributes?.typography?.img2?.data?.attributes?.url} className="img img-fluid" alt="" />
                                </div>
                                <div className="col-md-6 align-self-center text-center my-3">
                                    <img src={data?.attributes?.typography?.img3?.data?.attributes?.url} className="img img-fluid" alt="" />
                                </div>
                                <div className="col-md-6 align-self-center text-center my-3">
                                    <img src={data?.attributes?.typography?.img4?.data?.attributes?.url} className="img img-fluid" alt="" />
                                </div> */}
                            </div>
                        </div>
                    </div>

                    {/* Color Pallette */}
                    <div className={`color-pallette section ${data?.attributes?.ColorPalette <= 0 ? 'd-none' : 'd-block'}`}>
                        <h2 className='detail-title'>Color Palette</h2>
                        <div className="circle-bg">
                            <img src={BgCircle} className="img img-fluid" alt="circle_Design_cs1" />
                        </div>
                        <div className="color-pallette-imgs">
                            <div className="row">
                                <div className="col-md-4 my-3">
                                    <img src={data?.attributes?.ColorPalette[0]?.img?.data?.attributes?.url} className="img img-fluid" alt="" />
                                </div>
                                <div className="col-md-4 my-3">
                                    <img src={data?.attributes?.ColorPalette[1]?.img?.data?.attributes?.url} className="img img-fluid" alt="" />
                                </div>
                                <div className="col-md-4 my-3">
                                    <img src={data?.attributes?.ColorPalette[2]?.img?.data?.attributes?.url} className="img img-fluid" alt="" />
                                </div>
                                <div className="col-md-4 my-3">
                                    <img src={data?.attributes?.ColorPalette[3]?.img?.data?.attributes?.url} className="img img-fluid" alt="" />
                                </div>
                                <div className="col-md-4 my-3">
                                    <img src={data?.attributes?.ColorPalette[4]?.img?.data?.attributes?.url} className="img img-fluid" alt="" />
                                </div>
                                <div className="col-md-4 my-3">
                                    <img src={data?.attributes?.ColorPalette[5]?.img?.data?.attributes?.url} className="img img-fluid" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Icons */}
                    <div className={`icons section ${data?.attributes?.Icons <= 0 ? 'd-none' : 'd-block'}`}>
                        <h2 className='detail-title'>Icons</h2>
                        <div className="circle-bg">
                            <img src={BgCircle} className="img img-fluid" alt="circle_Design_cs1" />
                        </div>
                        <div className="icons-imgs">
                            <div className="row g-0">
                                <div className="col-md-12 d-none d-md-block">
                                    <img src={data?.attributes?.Icons?.img?.data?.attributes?.url} className="img img-fluid" alt="" />
                                </div>
                                <div className="col-md-12 d-block d-md-none">
                                    <img src={data?.attributes?.IconsMobile?.img?.data?.attributes?.url} className="img img-fluid" alt="" />
                                </div>
                                {/* <div className="col-md-6">
                                    <img src={data?.attributes?.Icons[0]?.img?.data?.attributes?.url} className="img img-fluid" alt="" />
                                </div>
                                <div className="col-md-6">
                                    <img src={data?.attributes?.Icons[1]?.img?.data?.attributes?.url} className="img img-fluid" alt="" />

                                </div>
                                <div className="col-md-6 order-2 order-md-1">
                                    <img src={data?.attributes?.Icons[2]?.img?.data?.attributes?.url} className="img img-fluid" alt="" />

                                </div>
                                <div className="col-md-6 order-1 order-md-2">
                                    <img src={data?.attributes?.Icons[3]?.img?.data?.attributes?.url} className="img img-fluid" alt="" />

                                </div> */}
                            </div>
                        </div>
                    </div>

                    {/* UI Elements Section */}
                    <div className={`ui-elements section ${data?.attributes?.uiElementsDesktop <= 0 ? 'd-none' : 'd-block'}`}>
                        {/* <div className={`ui-elements section ${data?.attributes?.UiElements <= 0 ? 'd-none' : 'd-block'}`}> */}
                        <h2 className='detail-title'>UI Elements </h2>
                        <div className="circle-bg">
                            <img src={BgCircle} className="img img-fluid" alt="circle_Design_cs1" />
                        </div>
                        <div className="ui-elements-imgs">
                            <div className="row">
                                <div className="col-md-12 text-center d-none d-md-block">
                                    <img src={data?.attributes?.uiElementsDesktop?.img?.data?.attributes?.url} className="img img-fluid applynow-img" alt="" />
                                </div>
                                <div className="col-md-12 text-center d-block d-md-none">
                                    <img src={data?.attributes?.uiElementsMobile?.img?.data?.attributes?.url} className="img img-fluid applynow-img" alt="" />
                                </div>
                                {/* <div className="col-md-6 text-center">
                                    <img src={data?.attributes?.UiElements[0]?.titleimg?.data?.attributes?.url} className="img img-fluid my-3 applynow-img" alt="" />
                                    <img src={data?.attributes?.UiElements[0]?.img?.data?.attributes?.url} className="img img-fluid" alt="" />
                                </div> */}
                                {/* <div className="col-md-6 text-center">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <img src={data?.attributes?.UiElements[1]?.titleimg?.data?.attributes?.url} className="img img-fluid my-3" alt="" />
                                            <img src={data?.attributes?.UiElements[1]?.img?.data?.attributes?.url} className="img img-fluid" alt="" />
                                        </div>
                                        <div className="col-md-6 text-center">
                                            <img src={data?.attributes?.UiElements[2]?.titleimg?.data?.attributes?.url} className="img img-fluid my-3" alt="" />
                                            <img src={data?.attributes?.UiElements[2]?.img?.data?.attributes?.url} className="img img-fluid" alt="" />
                                        </div>
                                        <div className="col-md-12 text-center">
                                            <img src={data?.attributes?.UiElements[3]?.titleimg?.data?.attributes?.url} className="img img-fluid my-3" alt="" />
                                            <img src={data?.attributes?.UiElements[3]?.img?.data?.attributes?.url} className="img img-fluid" alt="" />
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>

                    </div>

                    {/* User Interface Section */}
                    <div className={`wireframes section ${data?.attributes?.UserInterface <= 0 ? 'd-none' : 'd-block'}`}>
                        <h2 className='detail-title'>User Interface </h2>
                        <div className="circle-bg">
                            <img src={BgCircle} className="img img-fluid" alt="circle_Design_cs1" />
                        </div>
                        <div className="container">
                            <div className="wireframe-imgs">
                                <div className="row">
                                    <div className="col-md-6 my-3">
                                        <img src={data?.attributes?.UserInterface?.firstImg?.data?.attributes?.url} className="img img-fluid" alt="" />
                                    </div>
                                    <div className="col-md-6 my-3">
                                        <img src={data?.attributes?.UserInterface?.secondImg?.data?.attributes?.url} className="img img-fluid" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Responsive Design Section */}
                    <div className={`responsive-design section ${data?.attributes?.ResponsiveDesign <= 0 ? 'd-none' : 'd-block'}`}>
                        <h2 className='detail-title'>Responsive Design</h2>
                        <div className="circle-bg">
                            <img src={BgCircle} className="img img-fluid" alt="circle_Design_cs1" />
                        </div>
                        <div className="responsive-design-imgs">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 my-3">
                                        <div className="responsive-img-div">
                                            <img src={data?.attributes?.ResponsiveDesign[0]?.logoImg?.data[0]?.attributes?.url} className="img img-fluid" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 my-3">
                                        <div className="responsive-img-div">
                                            <img src={data?.attributes?.ResponsiveDesign[1]?.logoImg?.data[0]?.attributes?.url} className="img img-fluid" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 my-3">
                                        <div className="responsive-img-div">
                                            <img src={data?.attributes?.ResponsiveDesign[2]?.logoImg?.data[0]?.attributes?.url} className="img img-fluid" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 my-3">
                                        <div className="responsive-img-div">
                                            <img src={data?.attributes?.ResponsiveDesign[3]?.logoImg?.data[0]?.attributes?.url} className="img img-fluid" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 my-3">
                                        <div className="responsive-img-div">
                                            <img src={data?.attributes?.ResponsiveDesign[4]?.logoImg?.data[0]?.attributes?.url} className="img img-fluid" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}

export default Extendime
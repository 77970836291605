import React, { useCallback, useEffect, useState } from 'react'
import "./style.scss"
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useHistory } from "react-router-dom";
import Swal from 'sweetalert2'
import axios from 'axios';
import useFetch from '../../hooks/useFetch';
import loader from "../../assets/images/rainbow-loader.gif";
import LocationIcon from "../../assets/images/location-icon.svg"
import Location from "../../assets/images/icons/location.svg";
import Phone from "../../assets/images/icons/phone.svg";
import Globe from "../../assets/images/icons/globe.svg";
import CircleDesign from "../../assets/images/circle-design.svg"
import Loader from '../../components/loader';
const ContactPage = ({ setLoading }) => {
    let history = useHistory();
    const [location, setLocation] = useState([]);
    const [validated, setValidated] = useState(false);
    const [error, setError] = useState(null);
    // const [loader, setLoader] = useState(false);
    // const [ErrorState, setErrorState] = useState({
    //     name: false,
    //     PhoneNumber: false,
    //     email: false,
    //     message: false,
    // });

    // const [errorMsg, seterrorMsg] = useState({
    //     name: "Name is mandatory",
    //     PhoneNumber: "PhoneNumber is mandatory",
    //     email: "email is mandatory",
    //     message: "message is mandatory",
    // });
    const [modifiedData, setModifiedData] = useState({ name: '', PhoneNumber: '', email: '', message: '' });
    // const [modifiedData, setModifiedData] = useState({ name: '', email: '', phone: '', guests: '', date: '', time: '' });
    const handleInputChange = useCallback(({ target: { name, value } }) => {
        setModifiedData((prevData) => ({ ...prevData, [name]: value }));
    }, []);


    const handleSubmit = async (e) => {
        e.preventDefault();
        setValidated(true);
        // setLoading(true);
        await axios
            .post(`${process.env.REACT_APP_BASE_URL}/api/contacts?populate=deep`, ({ data: modifiedData }))
            // .post("http://52.5.5.222:1337/api/contacts?populate=deep", ({ data: modifiedData }))
            .then((response) => {
                // console.log("Contact response", response);
                // alert('form submitted Successfully')
                // setLoading(false);
                Swal.fire({
                    icon: 'success',
                    text: 'Form submitted Successfully',
                })
                history.push("/");
            })
            .catch((error) => {
                // setLoading(false);
                setError("error", error);

                // alert('form Submitted Unsuccessful')
                // window.location.reload();
            });


        // const { name, value } = e.target;
        // switch (true) {
        //     case name == "name":
        //         setModifiedData({ ...modifiedData, ...{ name: value } });
        //         if (value) {
        //             setErrorState({
        //                 ...ErrorState,
        //                 name: false,
        //             });
        //             seterrorMsg({
        //                 ...errorMsg,
        //                 name: "please",
        //             });
        //         } else {
        //             seterrorMsg({
        //                 ...errorMsg,
        //                 name: "name",
        //             });
        //             setErrorState({
        //                 ...ErrorState,
        //                 name: true,
        //             });
        //         }
        //         break;
        //     case name == "PhoneNumber":
        //         setModifiedData({ ...modifiedData, ...{ PhoneNumber: value } });
        //         if (value) {
        //             setErrorState({
        //                 ...ErrorState,
        //                 PhoneNumber: false,
        //             });
        //             seterrorMsg({
        //                 ...errorMsg,
        //                 PhoneNumber: "please",
        //             });
        //         } else {
        //             seterrorMsg({
        //                 ...errorMsg,
        //                 PhoneNumber: "name",
        //             });
        //             setErrorState({
        //                 ...ErrorState,
        //                 PhoneNumber: true,
        //             });
        //         }
        //         break;
        //     case name == "email":
        //         setModifiedData({ ...modifiedData, ...{ email: value } });
        //         if (value) {
        //             setErrorState({
        //                 ...ErrorState,
        //                 email: false,
        //             });
        //             seterrorMsg({
        //                 ...errorMsg,
        //                 email: "please",
        //             });
        //         } else {
        //             seterrorMsg({
        //                 ...errorMsg,
        //                 email: "name",
        //             });
        //             setErrorState({
        //                 ...ErrorState,
        //                 email: true,
        //             });
        //         }
        //         break;
        //     case name == "message":
        //         setModifiedData({ ...modifiedData, ...{ message: value } });
        //         if (value) {
        //             setErrorState({
        //                 ...ErrorState,
        //                 message: false,
        //             });
        //             seterrorMsg({
        //                 ...errorMsg,
        //                 message: "please",
        //             });
        //         } else {
        //             seterrorMsg({
        //                 ...errorMsg,
        //                 message: "name",
        //             });
        //             setErrorState({
        //                 ...ErrorState,
        //                 message: true,
        //             });
        //         }
        //         break;
        // }
    };

    // Locations Data
    let { loading, data } = useFetch(`${process.env.REACT_APP_BASE_URL}/api/contactform?populate=deep`);

    // let { loading, data } = useFetch('http://localhost:1337/api/contactform?populate=deep');

    useEffect(() => {
        if (data) {
            const locationdata = Object.values(data?.attributes?.Locations)
            // console.log("🚀 ~ file: index.js:13 ~ useEffect ~ colordata:", locationdata)
            setLocation(locationdata)
        }
    }, [data]);
    if (loading) return <div className='loader loader_wrapper text-center'><img src={loader} className="img img-fluid loader_img" alt="loader" /></div>

    return (
        <>
            <div className="contact-page">

                <div className="vector-img">
                    <img src={CircleDesign} className="img img-fluid" alt="circle" />
                </div>
                <div className="container">
                    <div className="contact-form">
                        <h2 className='contact-title'>Contact Us</h2>
                        <Form noValidate
                            validated={validated}
                            onSubmit={handleSubmit}
                        >
                            <Row className="mb-3">
                                <Form.Group as={Col} md="6" className='my-4' controlId="validationCustom02">
                                    {/* <Form.Label>Employee Id</Form.Label> */}
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        placeholder="Name"
                                        onChange={handleInputChange}
                                        value={modifiedData.name}
                                        className="contact-form-control"
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please choose Valid Name.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6" className='my-4' controlId="validationCustom01">
                                    <Form.Control
                                        type="number"
                                        name="PhoneNumber"
                                        pattern="[1-9]{1}[0-9]{9}"
                                        placeholder="Phone Number"
                                        onChange={handleInputChange}
                                        value={modifiedData.PhoneNumber}
                                        maxLength={10}
                                        minLength={10}
                                        className="contact-form-control"
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please choose Valid Phone Number.
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} md="6" className='my-4' controlId="validationCustom01">
                                    {/* <Form.Label>Employee Name</Form.Label> */}
                                    <Form.Control
                                        type="email"
                                        name="email"
                                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                                        placeholder="E-mail"
                                        onChange={handleInputChange}
                                        value={modifiedData.email}
                                        className="contact-form-control"
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please choose Valid Email.
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} md="6" className='my-4' controlId="validationCustom01">
                                    <textarea
                                        type="textarea"
                                        name="message"
                                        className='text-area contact-form-control'
                                        placeholder="Tell us about your project"
                                        onChange={handleInputChange}
                                        value={modifiedData.message}
                                        rows={1}
                                        required
                                    ></textarea >
                                    <Form.Control.Feedback type="invalid">
                                        Please Type Your Message.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <div className='text-left btns'>
                                <Button type="submit" className='book-btn'><b>Submit</b></Button>
                                {/* <a href="/" className='cancel-btn btn btn-primary'><b>Cancel</b></a> */}
                            </div>
                        </Form>
                    </div>


                    <div className="locations">
                        <div className="row">
                            {/* <div className="col-md-1">
                                <div className="adress-img">
                                    <img src={LocationIcon} className="img img-fluid" alt="location-icon" />
                                </div>
                            </div> */}
                            {location?.length >= 0 && location?.map((item, index) => {
                                // console.log("---------------->", item)
                                return (
                                    <>
                                        <div className="col-md-4 location">
                                            <div className="location-address">
                                                <div className="address-img">
                                                    <img src={item?.img?.data?.attributes?.url} className="img" alt="location-icon" />
                                                    {/* <img src={item?.img?.data?.attributes?.url} className="img img-fluid" alt="location-icon" /> */}
                                                </div>
                                                <div className='location-data'>
                                                    <h3 className='country-name'>{item?.countryName}</h3>
                                                    <div className=''>
                                                        <div className="clearfix">
                                                            <img src={Location} className="img img-fluid pull-left" alt="Location" />
                                                            <p className='address '>{item?.address}</p>
                                                            {/* <span>9999999999<br /><br /></span> */}
                                                        </div>
                                                        {/* <p className='address '>{item?.address}</p> */}
                                                        <div className="clearfix">
                                                            <img src={Phone} className="img img-fluid pull-left" alt="Location" />
                                                            <p className=''>{item?.PhoneNumber} &nbsp; {item?.MobileNumber}</p>
                                                            {/* <span>9999999999<br /><br /></span> */}
                                                        </div>
                                                        <div className="clearfix">
                                                            <img src={Globe} className="img img-fluid pull-left" alt="Location" />
                                                            <p className=''>{item?.email}</p>
                                                            {/* <span>9999999999<br /><br /></span> */}
                                                        </div>
                                                        {/* <p className=''>Service: {item?.MobileNumber}</p> */}

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                            }
                            {/* <div className="col-md-4"></div>
                            <div className="col-md-4"></div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Loader(ContactPage)
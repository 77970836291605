import React from 'react'
import useFetch from '../../hooks/useFetch';
import "./style.scss"
// import HomeBannerImg from "../../assets/images/bd1.png"
import loader from "../../assets/images/rainbow-loader.gif"
import DownArrow from "../../assets/images/icons/down_arrow.svg"
import HomeAboutbg from "../../assets/images/home-about-bg.png"
import Swal from 'sweetalert2';
const HomePage = () => {
    // let { loading, data, error } = useFetch('http://localhost:1337/api/homepage?populate=deep');
    let { loading, data, error } = useFetch(`${process.env.REACT_APP_BASE_URL}/api/homepage?populate=deep`);
    // let { loading, data, error } = useFetch('http://52.5.5.222:1337/api/homepage?populate=deep');
    console.log("🚀 ~ file: App.js:6 ~ App ~ homepage:", data)
    if (loading) return <div className='loader loader_wrapper text-center'><img src={loader} className="img img-fluid loader_img" alt="loader" /></div>
    if (error) {
        Swal.fire({
            icon: 'error',
            text: 'No Data Found',
        });
    }


    return (
        <>
            <div className='home'>
                <div className="container">
                    <div className="home-banner">
                        <div className="row">
                            <div className="col-md-7 align-self-center">
                                <div className="banner-title">
                                    <span className='banner-title1'>{data?.attributes?.bannerSection?.title1}</span><br />
                                    <span className='banner-title2'>{data?.attributes?.bannerSection?.title2}</span>
                                </div>
                                <div className='mt-5'>
                                    <img src={DownArrow} className="img img-fluid arrow-img" alt="mg" />
                                </div>
                            </div>
                            <div className="col-md-5">
                                <img src={data?.attributes?.bannerSection?.bannerimg?.data?.attributes?.url} className="img img-fluid" alt="banner-img" />
                            </div>
                        </div>
                    </div>

                    {/* About-Home Section */}
                    <div className="about-home">
                        <h2 className='title'>{data?.attributes?.About?.title}</h2>
                        <div className="row">
                            <div className="col-md-6">
                                <p className='about-text-bold'>{data?.attributes?.About?.abouttextleft}</p>
                            </div>
                            <div className="col-md-6">
                                <p className='about-text'>{data?.attributes?.About?.abouttextright}</p>
                            </div>
                        </div>

                        <div className="brand-circle1">
                            <img src={HomeAboutbg} alt="half-brand-circle" className="img-fluid" />
                        </div>

                    </div>
                    {/* Branding Design Section */}
                    <div className="branding-design">
                        <div className="row">
                            <div className="branding-img">
                                <img src={data?.attributes?.brandingDesign?.image?.data?.attributes?.url} className="img img-fluid branding-image" alt="branding" />
                            </div>
                        </div>
                        <div className="row brand-row">
                            <div className="col-md-5">
                                <h2 className='title '>{data?.attributes?.brandingDesign?.title}</h2>
                            </div>
                            <div className="col-md-7">
                                <p className='brand-text'>{data?.attributes?.brandingDesign?.description}</p>
                            </div>
                        </div>
                    </div>

                    {/* UI/UX Design Section */}
                    <div className="ui-ux-design">
                        <div className="row">
                            <div className="ux-img">
                                <img src={data?.attributes?.uiuxDesign?.image?.data?.attributes?.url} className="img img-fluid ux-image" alt="branding" />
                            </div>
                        </div>
                        <div className="row ux-row">
                            <div className="col-md-5">
                                <h2 className='title '>{data?.attributes?.uiuxDesign?.title}</h2>
                            </div>
                            <div className="col-md-7">
                                <p className='ux-text'>{data?.attributes?.uiuxDesign?.description}</p>
                            </div>
                        </div>
                    </div>

                    {/* Works-Section */}
                    <div className="works-section">
                        <h2 className='title '>Works</h2>
                        <div className="row">
                            <div className="col-md-6 my-2">
                                <div className="image">
                                    <img src={data?.attributes?.Work?.workimg1?.data?.attributes?.url} className="img img-fluid work-img" alt="works-imgs" />
                                    <div className="overlay">
                                        <div className="text">Branding</div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-12 my-2">
                                        <div className="image">
                                            <img src={data?.attributes?.Work?.workimg2?.data?.attributes?.url} className="img img-fluid work-img" alt="works-imgs" />
                                            <div className="overlay">
                                                <div className="text">UI/ UX design</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 my-2">
                                        <div className="image">
                                            <img src={data?.attributes?.Work?.workimg3?.data?.attributes?.url} className="img img-fluid work-img" alt="works-imgs" />
                                            <div className="overlay">
                                                <div className="text">Logo design</div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-6 my-2">
                                        <img src={data?.attributes?.Work?.workimg4?.data?.attributes?.url} className="img img-fluid work-img" alt="works-imgs" />
                                    </div>
                                    <div className="col-md-6 my-2">
                                        <img src={data?.attributes?.Work?.workimg5?.data?.attributes?.url} className="img img-fluid work-img" alt="works-imgs" />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className='text-end mt-5'>
                            <a href='/works' className='view-btn'>View All</a>
                        </div>
                    </div>

                    {/* To Find More */}
                    <div className="find-out-more">
                        <h2 className='find-out-title'>To find out more about us.</h2>

                        <div className="touch-btn my-5">
                            <a href="/contact" className='btn'>Get In Touch</a>
                            {/* <button className='btn btn-primary'>Get In Touch</button> */}
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default HomePage